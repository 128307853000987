<template>
  <orders-page
    title="Today's Orders"
    :show-date-filter="false"
    :include-completed-orders="false"
    today-only
  />
</template>

<script>
import OrdersPage from "./index.vue"
import { mapMutations, mapState } from "vuex"

export default {
  name: "todays-orders",
  components: {
    OrdersPage,
  },
  computed: {
    ...mapState(["soundOn"]),
  },
  mounted() {
    if (!this.soundOn) {
      this.setNotification({
        message: "Sound is currently off",
        icon: "volume-high",
        browserOnly: true,
        forceBrowser: true,
      })
    }
  },
  methods: {
    ...mapMutations(["setNotification"]),
  },
}
</script>
